import { BiUser, BiGame, BiMoneyWithdraw } from "react-icons/bi";
import SettingsIcon from '@mui/icons-material/Settings';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PeopleIcon from '@mui/icons-material/People';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import HomeIcon from '@mui/icons-material/Home';

export const RouteName = [
    {
        path: "/admin",
        name: "Dashboard",
        icon: <HomeIcon />,
    },
    {
        name: "Game History",
        icon: <SportsEsportsIcon />,
        subRoutes: [
            {
                path: "/admin/game-history/fun-target-timer",
                name: "Fun Target Timer",
                icon: <BiGame />,
            },
            {
                path: "/admin/game-history/fun-sorat-timer",
                name: "Fun Sorat Timer",
                icon: <BiGame />,
            },
            {
                path: "/admin/game-history/prologic-777",
                name: "Prologic 777",
                icon: <BiGame />,
            },
        ],
    },
    // {
    //     name: "Customers",
    //     icon: <PeopleIcon />,
    //     subRoutes: [
    //         {
    //             path: "/admin/customer/Our-Customer",
    //             name: "All Customer",
    //             icon: <BiMoneyWithdraw />,
    //         }
    //     ],
    // },
    // {
    //     name: "Team",
    //     icon: <PeopleIcon />,
    //     subRoutes: [
    //         {
    //             path: "/admin/userTeam/user-team",
    //             name: "User Team",
    //             icon: <BiMoneyWithdraw />,
    //         }
    //     ],
    // },
    {
        name: "Current Game",
        icon: <PeopleIcon />,
        subRoutes: [
            {
                path: "/admin/current-game/fun-target-timer",
                name: "Fun Target Timer",
                icon: <BiMoneyWithdraw />,
            },
            {
                path: "/admin/current-game/fun-sorat-timer",
                name: "Fun Sorat Timer",
                icon: <BiMoneyWithdraw />,
            },
            {
                path: "/admin/current-game/prologic-777",
                name: "Prologic 777",
                icon: <BiMoneyWithdraw />,
            }
        ],
    },
    {
        name: "Withdraw",
        icon: <PeopleIcon />,
        subRoutes: [
            {
                path: "/admin/withdraw-history/withdraw-history",
                name: "Withdraw history",
                icon: <BiMoneyWithdraw />,
            }
        ],
    },
    {
        name: "Deposit",
        icon: <PeopleIcon />,
        subRoutes: [
            {
                path: "/admin/deposit-history/deposit-history",
                name: "Deposit history",
                icon: <BiMoneyWithdraw />,
            }
        ],
    },
    {
        name: "Transaction",
        icon: <AccountBalanceIcon />,
        subRoutes: [
            {
                path: "/admin/company/all-company",
                name: "Pending",
                icon: <BiMoneyWithdraw />,
            },
            {
                path: "/admin/company/add-company",
                name: "Complete",
                icon: <BiMoneyWithdraw />,
            }
        ],
    },
    // {
    //     name: "User Teams",
    //     icon: <AccountBalanceIcon />,
    //     subRoutes: [
    //         {
    //             path: "/admin/items/all-items",
    //             name: "User Teams",
    //             icon: <BiMoneyWithdraw />,
    //         }
    //     ],
    // },
    // {
    //     name: "Setting",
    //     icon: <SettingsIcon />,
    //     subRoutes: [
    //         {
    //             path: "/admin/setting/blogs",
    //             name: "Blogs",
    //             icon: <BiUser />,
    //         },
    //         {
    //             path: "/admin/setting/about-us",
    //             name: "About Us",
    //             icon: <BiUser />,
    //         },
    //         {
    //             path: "/admin/setting/terms-and-conditions",
    //             name: "Terms & Conditions",
    //             icon: <BiUser />,
    //         },
    //         {
    //             path: "/admin/setting/privacy-policy",
    //             name: "Privacy Policy",
    //             icon: <BiUser />,
    //         },
    //         {
    //             path: "/admin/setting/support/category",
    //             name: "Support",
    //             icon: <BiUser />,
    //         },
    //         {
    //             path: "/admin/setting/support-query",
    //             name: "Support Query",
    //             icon: <BiUser />,
    //         },
    //         {
    //             path: "/admin/setting/sub-admin",
    //             name: "Sub Admin",
    //             icon: <BiUser />,
    //         },
    //         {
    //             path: "/admin/setting/change-password",
    //             name: "Change Password",
    //             icon: <BiUser />,
    //         },
    //     ]
    // },
];