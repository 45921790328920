import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PrivateRoutes from "./utils/private-routes";
import Login from './pages/login';

//! Dahboard
import Dashboard from "./pages/dashboard";

//! Setting 
import Blogs from "./pages/setting/blogs";
import AddBlog from "./pages/setting/blogs/add-blogs";
import AboutUs from "./pages/setting/about-us";
import TermsAndConditions from "./pages/setting/terms-and-conditions";
import PrivacyPolicy from "./pages/setting/privacy-policy";
import ChangePassword from "./pages/setting/change-password";
import Subadmin from "./pages/setting/sub-admin";
import AddSubadmin from "./pages/setting/sub-admin/add-sub-admin";

//! Current Game
import CurrentGameFunTargetTimer from "./pages/current-game/fun-target-timer";
import CurrentGameFunSoratTimer from "./pages/current-game/fun-sorat-timer";
import CurrentGamePrologicTimer from "./pages/current-game/prologic-timer";

//! Game
import GameHistoryFunTargetTimer from "./pages/game-history/fun-target-timer";
import GameHistoryFunSoratTimer from "./pages/game-history/fun-sorat-game";
import GameHistoryPrologicTimer from "./pages/game-history/prologic777";

//! Customer
import AllCustomers from "./pages/customer/Our-Customer";

//! User Team
import UserTeam from "./pages/userTeam/user-team";

//! Withdraw
import Withdraw from "./pages/withdraw-history/withdraw-history";

//! Deposit History
import Deposit from "./pages/deposit-history/deposit-history";



function App() {
  return (
    <Router>
      <Routes>
        <Route path='/login' element={<Login />} />

        <Route path="/" element={<PrivateRoutes />}>
          <Route path="/admin" element={<Dashboard />} />
          <Route path="*" element={<> Not Ready</>} />

          {/* Setting */}
          <Route path="/admin/setting/blogs" element={<Blogs />} />
          <Route path="/admin/setting/blogs/add-blog" element={<AddBlog mode={'Add'} />} />
          <Route path="/admin/setting/blogs/edit-blog" element={<AddBlog mode={'Edit'} />} />

          <Route path="/admin/setting/about-us" element={<AboutUs />} />
          <Route path="/admin/setting/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/admin/setting/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/admin/setting/change-password" element={<ChangePassword />} />

          <Route path="/admin/setting/sub-admin" element={<Subadmin />} />
          <Route path="/admin/setting/add-sub-admin" element={<AddSubadmin mode={'Add'} />} />
          <Route path="/admin/setting/edit-sub-admin" element={<AddSubadmin mode={'Edit'} />} />

          {/* Current Game */}
          <Route path="/admin/current-game/fun-target-timer" element={<CurrentGameFunTargetTimer />} />
          <Route path="/admin/current-game/fun-sorat-timer" element={<CurrentGameFunSoratTimer />} />
          <Route path="/admin/current-game/prologic-777" element={<CurrentGamePrologicTimer />} />

          {/* Game */}
          <Route path="/admin/game-history/fun-target-timer" element={<GameHistoryFunTargetTimer />} />
          <Route path="/admin/game-history/fun-sorat-timer" element={<GameHistoryFunSoratTimer />} />
          <Route path="/admin/game-history/prologic-777" element={<GameHistoryPrologicTimer />} />

          {/* Customer */}
          <Route path="/admin/customer/Our-customer" element={<AllCustomers />} />

          {/* Customer */}
          <Route path="/admin/userTeam/user-team" element={<UserTeam />} />


          {/* Withraw history */}
          <Route path="/admin/withdraw-history/withdraw-history" element={<Withdraw />} />

          {/* Deposite History */}
          <Route path="/admin/deposit-history/deposit-history" element={<Deposit />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;