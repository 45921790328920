//! Common
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_IS_SIDEBAR_OPEN = 'SET_IS_SIDEBAR_OPEN';

//! Auth
export const ADMIN_LOGIN = 'ADMIN_LOGIN';

//! Dashboard

//! Game History
export const GET_FTT_GAME_HISTORY = 'GET_FTT_GAME_HISTORY';
export const SET_FTT_GAME_HISTORY = 'SET_FTT_GAME_HISTORY';
export const GET_FST_GAME_HISTORY = 'GET_FST_GAME_HISTORY';
export const SET_FST_GAME_HISTORY = 'SET_FST_GAME_HISTORY';
export const GET_PT_GAME_HISTORY = 'GET_PT_GAME_HISTORY';
export const SET_PT_GAME_HISTORY = 'SET_PT_GAME_HISTORY';

//! Current Game
//* Fun Target Timer
export const GET_CURRENT_FTT_GAME_ID = 'GET_CURRENT_FTT_GAME_ID';
export const SET_CURRENT_FTT_GAME_ID = 'SET_CURRENT_FTT_GAME_ID';
export const GET_CURRENT_FTT_GAME_TOTAL_BETS = 'GET_CURRENT_FTT_GAME_TOTAL_BETS';
export const SET_CURRENT_FTT_GAME_TOTAL_BETS = 'SET_CURRENT_FTT_GAME_TOTAL_BETS';
export const GET_CURRENT_FTT_GAME_TIMER = 'GET_CURRENT_FTT_GAME_TIMER';
export const SET_CURRENT_FTT_GAME_TIMER = 'SET_CURRENT_FTT_GAME_TIMER';
export const CURRENT_FTT_GAME_WINNER_BY_ADMIN = 'CURRENT_FTT_GAME_WINNER_BY_ADMIN';

//* Fun Sorat Timer
export const GET_CURRENT_FST_GAME_ID = 'GET_CURRENT_FST_GAME_ID';
export const SET_CURRENT_FST_GAME_ID = 'SET_CURRENT_FST_GAME_ID';
export const GET_CURRENT_FST_GAME_TOTAL_BETS = 'GET_CURRENT_FST_GAME_TOTAL_BETS';
export const SET_CURRENT_FST_GAME_TOTAL_BETS = 'SET_CURRENT_FST_GAME_TOTAL_BETS';
export const GET_CURRENT_FST_GAME_TIMER = 'GET_CURRENT_FST_GAME_TIMER';
export const SET_CURRENT_FST_GAME_TIMER = 'SET_CURRENT_FST_GAME_TIMER';
export const CURRENT_FST_GAME_WINNER_BY_ADMIN = 'CURRENT_FST_GAME_WINNER_BY_ADMIN';

//* Prologic Timer
export const GET_CURRENT_PT_GAME_ID = 'GET_CURRENT_PT_GAME_ID';
export const SET_CURRENT_PT_GAME_ID = 'SET_CURRENT_PT_GAME_ID';
export const GET_CURRENT_PT_GAME_TOTAL_BETS = 'GET_CURRENT_PT_GAME_TOTAL_BETS';
export const SET_CURRENT_PT_GAME_TOTAL_BETS = 'SET_CURRENT_PT_GAME_TOTAL_BETS';
export const GET_CURRENT_PT_GAME_TIMER = 'GET_CURRENT_PT_GAME_TIMER';
export const SET_CURRENT_PT_GAME_TIMER = 'SET_CURRENT_PT_GAME_TIMER';
export const CURRENT_PT_GAME_WINNER_BY_ADMIN = 'CURRENT_PT_GAME_WINNER_BY_ADMIN';