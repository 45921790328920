import { all } from "redux-saga/effects";
import authSaga from "./authSaga";
import gameHistorySaga from './gameHistorySaga';
import currentGameSaga from './currentGameSaga';

export default function* rootSaga() {
    yield all([
        authSaga(),
        gameHistorySaga(),
        currentGameSaga()
    ])
};