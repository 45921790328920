import { combineReducers } from "redux";
import commonReducer from "./commonReducer";
import gameHistoryReducer from "./gameHistoryReducer";
import currentGameReducer from "./currentGameReducer";

const rootReducer = combineReducers({
    commonReducer,
    gameHistoryReducer,
    currentGameReducer,
});

export default rootReducer;